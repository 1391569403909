import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { poolData, TOKEN_ADDRESS, TOKEN_TICKER } from "./poolData";
import { useAccount } from "wagmi";
import TokenABI from "../abi/Token.json";
import TokenStakingPoolABI from "../abi/TokenStakingPool.json";
import "./StakingPools.css"; // Ensure this is imported
import { useBalance } from "wagmi";

const Button = ({ onClick, children, className, disabled }) => (
  <button onClick={onClick} className={`btn ${className}`} disabled={disabled}>
    {children}
  </button>
);

const StakingPools = () => {
  const [signer, setSigner] = useState(null);
  const [contracts, setContracts] = useState({});
  const { address } = useAccount();
  const [tokenFullBalance, setTokenFullBalance] = useState("0");
  const [isNetworkCorrect, setIsNetworkCorrect] = useState(true);
  const [stakedPools, setStakedPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmUnstake, setConfirmUnstake] = useState(false);
  const [confirmClaimRewards, setConfirmClaimRewards] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [selectedPercentage, setSelectedPercentage] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [timelockWarning, setTimelockWarning] = useState({
    show: false,
    unlockTime: null,
  });
  const [compoundMessage, setCompoundMessage] = useState(false);
  const [noRewardsWarning, setNoRewardsWarning] = useState({
    show: false,
    message: "",
  });
  const [walletError, setWalletError] = useState("");
  const { data: balanceData } = useBalance({
    address: address,
    token: TOKEN_ADDRESS,
  });

  const resetStates = () => {
    setSigner(null);
    setContracts({});
    setTokenFullBalance("0");
    setIsNetworkCorrect(true);
    setStakedPools([]);
    setSelectedPool(null);
    setTokenContract(null);
    setErrorMessage("");
    setConfirmUnstake(false);
    setConfirmClaimRewards(false);
    setPendingAction(null);
    setActionInProgress(false);
    setStatusMessage("");
    setSuccessMessage("");
    setTimelockWarning({ show: false, unlockTime: null });
    setCompoundMessage(false);
    setNoRewardsWarning({ show: false, message: "" });
    setWalletError("");
  };

  useEffect(() => {
    const init = async () => {
      console.log("init function called");

      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      setSigner(signer);

      const tokenContractInstance = new ethers.Contract(
        TOKEN_ADDRESS,
        TokenABI,
        signer
      );
      setTokenContract((_tokenContractInstance) => tokenContractInstance);
      console.log("tokenContract:", tokenContract, tokenContractInstance);
      await handleAccountsChanged(address);
    };

    init();
  }, [address]);

  const calculateTimeLeft = (unlockTime) => {
    const now = new Date();
    const timeLeft = new Date(unlockTime) - now;
    if (timeLeft <= 0) {
      return "Already Unlocked!";
    }
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${daysLeft}d ${hoursLeft}h ${minutesLeft}m`;
  };

  const handleAccountsChanged = async (address) => {
    if (address) {
      await updateStakedPools(address);
    } else {
      resetStates();
    }
  };

  useEffect(() => {
    if (balanceData) {
      setTokenFullBalance(balanceData.formatted);
    }
  }, [balanceData]);

  const updateStakedPools = async (address) => {
    if (!window.ethereum) return;

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    setSigner(signer);

    const contractInstances = poolData.reduce((instances, pool) => {
      try {
        instances[pool.id] = new ethers.Contract(
          pool.address,
          pool.abi,
          signer
        );
      } catch (error) {
        console.error(
          `Failed to initialize contract for pool ${pool.id}`,
          error
        );
      }
      return instances;
    }, {});

    setContracts((_contractInstances) => contractInstances);

    if (contractInstances) {
      const stakes = [];
      for (const pool of poolData) {
        const contract = contractInstances[pool.id];
        if (contract) {
          try {
            const share = await contract.shares(address);
            const total_share = await contract.totalSharesDeposited();
            console.log("share: ", share.amount, total_share);
            const ethReward = await contract.getUnpaid(address);

            const tokenStakingContract = new ethers.Contract(
              pool.tokenPoolAddress,
              TokenStakingPoolABI,
              signer
            );
            const tokenReward = await tokenStakingContract.rewardOf(address);

            const lockupPeriodInSeconds = pool.lockupPeriod;// * 24 * 60 * 60;
            const unlockTime = new Date(
              (Number(share.stakedTime) + lockupPeriodInSeconds) * 1000
            );
            pool.stakedFull = ethers.formatEther(share.amount);
            pool.total_stakedFull = ethers.formatEther(total_share);
            stakes.push({
              pool,
              staked: parseFloat(ethers.formatEther(share.amount)).toFixed(4),
              stakedFull: ethers.formatEther(share.amount),
              total_staked: parseFloat(ethers.formatEther(total_share)).toFixed(
                4
              ),
              total_stakedFull: ethers.formatEther(total_share),
              ethRewards: ethers.formatEther(ethReward),
              tokenRewards: parseFloat(ethers.formatEther(tokenReward)).toFixed(
                4
              ),
              tokenRewardsFull: ethers.formatEther(tokenReward),
              unlockTime,
            });
          } catch (error) {
            console.error(
              `Failed to fetch stake data for pool ${pool.id}`,
              error
            );
          }
        }
      }
      setStakedPools(stakes);
      console.log("Updated staked pools:", stakes);
    }
  };

  const handleStake = async (poolId, amount) => {
    setActionInProgress(true);
    setStatusMessage("Waiting for Wallet confirmation...");
    setWalletError("");
    try {
      if (
        !signer ||
        !contracts[poolId] ||
        !tokenContract ||
        !amount ||
        !isNetworkCorrect
      ) {
        console.error("Staking failed: Missing required data");
        return;
      }

      const contractWithSigner = contracts[poolId].connect(signer);
      const poolAddress = await contractWithSigner.getAddress();

      console.log("Pool Address:", poolAddress);
      console.log("Amount to stake:", amount);

      const approveTx = await tokenContract.approve(
        poolAddress,
        ethers.parseEther(amount)
      );
      console.log("Approval transaction:", approveTx.hash);
      await approveTx.wait();
      console.log("Approval confirmed");

      setStatusMessage("Staking in progress...");
      const stakeTx = await contractWithSigner.stake(ethers.parseEther(amount));
      console.log("Stake transaction:", stakeTx.hash);
      await stakeTx.wait();
      console.log("Stake confirmed");

      updateStakedPools(address);
      setSuccessMessage("Staking successful!");
    } catch (error) {
      if (error.code === 4001) {
        setWalletError(
          "Transaction was rejected. Please approve the transaction in your wallet."
        );
      } else if (error.code === -32002) {
        setWalletError(
          "Another wallet request is pending. Please check Wallet."
        );
      } else {
        console.error("Staking failed:", error);
        setWalletError("Staking failed. Please try again.");
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage("");
      setSelectedPool(null);
    }
  };

  const handleUnstake = async (poolId, amount) => {
    console.log("handleUnstake", poolId, amount);
    const stakedPool = stakedPools.find((pool) => pool.pool.id === poolId);
    if (stakedPool && stakedPool.unlockTime > new Date()) {
      setTimelockWarning({ show: true, unlockTime: stakedPool.unlockTime });
      return;
    }

    if (stakedPool && parseFloat(stakedPool.ethRewards) > 0) {
      setPendingAction(() => () => executeUnstake(poolId, amount));
      setConfirmUnstake(true);
      return;
    }
    console.log("Unstake: ", poolId, amount);
    executeUnstake(poolId, amount);
  };

  const executeUnstake = async (poolId, amount) => {
    setActionInProgress(true);
    setStatusMessage("Waiting for Wallet confirmation...");
    setWalletError("");
    try {
      if (contracts[poolId] && amount && isNetworkCorrect) {
        console.log("executeUnstake: ", poolId, amount);
        console.log(
          "ethers.parseEther(amount): ",
          ethers.parseEther(amount.toString())
        );
        const contractWithSigner = contracts[poolId].connect(signer);
        const tx = await contractWithSigner.unstake(
          ethers.parseEther(amount.toString())
        );
        await tx.wait();
        updateStakedPools(address);
        setSuccessMessage("Unstaking successful!");
      }
    } catch (error) {
      if (error.code === 4001) {
        setWalletError(
          "Transaction was rejected. Please approve the transaction in your wallet."
        );
      } else if (error.code === -32002) {
        setWalletError(
          "Another wallet request is pending. Please check Wallet."
        );
      } else {
        console.error("Unstaking failed:", error);
        setWalletError("Unstaking failed. Please try again.");
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage("");
      setConfirmUnstake(false);
    }
  };

  const handleClaimRewards = async (
    poolId,
    compound = false,
    minTokens = 0
  ) => {
    console.log("handleClaimRewards" + poolId);
    const stakedPool = stakedPools.find((pool) => pool.pool.id === poolId);
    console.log("stakedPool" + stakedPool);
    if (stakedPool) {
      if (parseFloat(stakedPool.ethRewards) <= 0) {
        setNoRewardsWarning({
          show: true,
          message: "No ETH rewards to claim.",
        });
        return;
      }
      setPendingAction(
        () => () => executeClaimRewards(poolId, compound, minTokens)
      );
      setCompoundMessage(compound);
      setConfirmClaimRewards(true);
      return;
    }

    executeClaimRewards(poolId, compound, minTokens);
  };

  const executeClaimRewards = async (
    poolId,
    compound = false,
    minTokens = 0
  ) => {
    setActionInProgress(true);
    setStatusMessage("Waiting for Wallet confirmation...");
    setWalletError("");
    try {
      if (contracts[poolId] && isNetworkCorrect) {
        const contractWithSigner = contracts[poolId].connect(signer);
        const tx = await contractWithSigner.claimReward(compound, minTokens);
        await tx.wait();
        updateStakedPools(address);
        setSuccessMessage(
          `Claiming ETH rewards successful${
            compound ? " and compounding" : ""
          }!`
        );
      }
    } catch (error) {
      if (error.code === 4001) {
        setWalletError(
          "Transaction was rejected. Please approve the transaction in your wallet."
        );
      } else if (error.code === -32002) {
        setWalletError(
          "Another wallet request is pending. Please check Wallet."
        );
      } else {
        console.error("Claiming rewards failed:", error);
        setWalletError("Claiming rewards failed. Please try again.");
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage("");
      setConfirmClaimRewards(false);
      setCompoundMessage(false);
    }
  };

  const handleClaimTokenRewards = async (tokenPoolAddress) => {
    const stakedPool = stakedPools.find(
      (pool) => pool.pool.tokenPoolAddress === tokenPoolAddress
    );
    if (stakedPool) {
      if (parseFloat(stakedPool.tokenRewardsFull) <= 0) {
        setNoRewardsWarning({
          show: true,
          message: "No token rewards to claim.",
        });
        return;
      }
    }

    setActionInProgress(true);
    setStatusMessage("Waiting for Wallet confirmation...");
    setWalletError("");
    try {
      if (!signer || !isNetworkCorrect) {
        console.error("Claiming token rewards failed: Missing required data");
        return;
      }

      const tokenStakingContract = new ethers.Contract(
        tokenPoolAddress,
        TokenStakingPoolABI,
        signer
      );
      const claimTx = await tokenStakingContract.claimRewards();
      await claimTx.wait();
      console.log("Token rewards claimed");
      updateStakedPools(address);
      setSuccessMessage("Claiming token rewards successful!");
    } catch (error) {
      if (error.code === 4001) {
        setWalletError(
          "Transaction was rejected. Please approve the transaction in your wallet."
        );
      } else if (error.code === -32002) {
        setWalletError(
          "Another wallet request is pending. Please check Wallet."
        );
      } else {
        console.error("Claiming token rewards failed:", error);
        setWalletError("Claiming token rewards failed. Please try again.");
      }
    } finally {
      setActionInProgress(false);
      setStatusMessage("");
    }
  };

  const renderPools = () => {
    return poolData.map((pool) => (
      <div key={pool.id} className="pool">
        <div className="stake-logo">
          <div className="logo-section">
            <img src="logo.png" alt="Logo" className="stake-image" />
            <span className="stake-label">{TOKEN_TICKER}</span>
          </div>
          <h2>{pool.label}</h2>
        </div>
        <p>
          <span className="label">APR:</span>{" "}
          <span className="value">{pool.apr}%</span>
        </p>
        <p>
          <span className="label">ETH Revenue Share:</span>{" "}
          <span className="value">{pool.ethPerencent}%</span>
        </p>
        <p>
          <span className="label">Lockup Period:</span>{" "}
          <span className="value">{pool.lockupPeriod} days</span>
        </p>
        <p>
          <span className="label">Total Staked:</span>{" "}
          <span className="value">
            {isNaN(parseFloat(pool.total_stakedFull))
              ? "..."
              : parseFloat(pool.total_stakedFull).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </span>
        </p>
        <Button
          onClick={() => handlePoolClick(pool)}
          disabled={actionInProgress}
          className="stakebutton"
        >
          Stake Now
        </Button>
      </div>
    ));
  };

  const handlePoolClick = async (pool) => {
    setSelectedPool(pool);
  };

  const renderStakedPools = () => {
    const isNewDisplay = false; // Toggle this flag to switch between the old and new display

    return stakedPools.map((stakedPool, index) => {
      const stakedAmount = parseFloat(stakedPool.staked);
      const isStaked = stakedAmount > 0;

      if (isNewDisplay) {
        // New Display Format
        return (
          <div key={index} className="staked-pool">
            <div className="staked-pool-info">
              <div className="stake-logo">
                <div className="imageSection">
                  <img src="logo.png" alt="Logo" />
                  <label>{TOKEN_TICKER}</label>
                </div>
                <h3>
                  {stakedPool.pool.label} - {stakedPool.pool.apr}% APR
                </h3>
              </div>
              <p>
                <span className="label">Staked:</span>{" "}
                <span className="value">
                  {isStaked
                    ? `${parseFloat(stakedPool.staked).toLocaleString(
                        undefined,
                        { minimumFractionDigits: 2, maximumFractionDigits: 4 }
                      )} ${TOKEN_TICKER}`
                    : `0.0000 ${TOKEN_TICKER}`}
                </span>
              </p>
              <p>
                <span className="label">Ownership:</span>{" "}
                <span className="value">
                  {(
                    (Number(stakedPool.staked) /
                      Number(stakedPool.total_staked)) *
                    100
                  ).toFixed(2)}
                  %
                </span>
              </p>
              <p>
                <span className="label">Token Rewards:</span>{" "}
                <span className="value">
                  {isStaked
                    ? `${stakedPool.tokenRewards} ${TOKEN_TICKER}`
                    : "N/A"}
                </span>
              </p>
              <p>
                <span className="label">ETH Rewards:</span>{" "}
                <span className="value">
                  {isStaked ? `${stakedPool.ethRewards} ETH` : "N/A"}
                </span>
              </p>
              <p>
                <span className="label">Unlock At:</span>{" "}
                <span className="value">
                  {isStaked ? stakedPool.unlockTime.toLocaleString() : "N/A"}
                </span>
              </p>
              <p>
                <span className="label">Unlock In:</span>{" "}
                <span className="value">
                  {isStaked ? calculateTimeLeft(stakedPool.unlockTime) : "N/A"}
                </span>
              </p>
            </div>
            <div className="staked-pool-actions">
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id, true)}
                disabled={!isStaked || actionInProgress}
                className="firstLine"
              >
                Compund ETH
              </Button>
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id)}
                disabled={!isStaked || actionInProgress}
                className="firstLine"
              >
                Claim ETH
              </Button>
              <Button
                onClick={() =>
                  handleClaimTokenRewards(stakedPool.pool.tokenPoolAddress)
                }
                disabled={!isStaked || actionInProgress}
                className="secondLine"
              >
                Claim Tokens
              </Button>
              <Button
                onClick={() =>
                  handleUnstake(stakedPool.pool.id, stakedPool.stakedFull)
                }
                disabled={!isStaked || actionInProgress}
                className="secondLine"
              >
                Unstake
              </Button>
            </div>
          </div>
        );
      } else {
        // Original Display Format
        return (
          <div key={index} className="staked-pool">
            <div className="staked-pool-info">
              <div className="stake-logo">
                <div className="imageSection">
                  <img src="logo.png" alt="Logo" />
                  <label>{TOKEN_TICKER}</label>
                </div>
                <h3>
                  {stakedPool.pool.label} - {stakedPool.pool.apr}% APR
                </h3>
              </div>
              {isStaked ? (
                <>
                  <p>
                    <span className="label">Staked:</span>{" "}
                    <span className="value">{`${parseFloat(
                      stakedPool.staked
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 4,
                    })} ${TOKEN_TICKER}`}</span>
                  </p>
                  <p>
                    <span className="label">Ownership:</span>{" "}
                    <span className="value">
                      {(
                        (Number(stakedPool.staked) /
                          Number(stakedPool.total_staked)) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  </p>
                  <p>
                    <span className="label">Token Rewards:</span>{" "}
                    <span className="value">{`${stakedPool.tokenRewards} ${TOKEN_TICKER}`}</span>
                  </p>
                  <p>
                    <span className="label">ETH Rewards:</span>{" "}
                    <span className="value">{`${stakedPool.ethRewards} ETH`}</span>
                  </p>
                  <p>
                    <span className="label">Unlock At:</span>{" "}
                    <span className="value">
                      {stakedPool.unlockTime.toLocaleString()}
                    </span>
                  </p>
                  <p>
                    <span className="label">Unlock In:</span>{" "}
                    <span className="value">
                      {calculateTimeLeft(stakedPool.unlockTime)}
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <p>You don't have any staked {TOKEN_TICKER} in this pool.</p>
                  <p>Please stake {TOKEN_TICKER} to earn rewards.</p>
                </>
              )}
            </div>
            <div className="staked-pool-actions">
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id, true)}
                disabled={!isStaked || actionInProgress}
                className="firstLine"
              >
                Compund ETH
              </Button>
              <Button
                onClick={() => handleClaimRewards(stakedPool.pool.id)}
                disabled={!isStaked || actionInProgress}
                className="firstLine"
              >
                Claim ETH
              </Button>
              <Button
                onClick={() =>
                  handleClaimTokenRewards(stakedPool.pool.tokenPoolAddress)
                }
                disabled={!isStaked || actionInProgress}
                className="secondLine"
              >
                Claim Tokens
              </Button>
              <Button
                onClick={() =>
                  handleUnstake(stakedPool.pool.id, stakedPool.stakedFull)
                }
                disabled={!isStaked || actionInProgress}
                className="secondLine"
              >
                Unstake
              </Button>
            </div>
          </div>
        );
      }
    });
  };

  const handleMaxClick = (amount) => {
    setSelectedPercentage(amount);
    console.log("amount percent:", amount);
    if (selectedPool) {
      const stakeInput = document.getElementById("stake-amount-input");
      if (stakeInput)
        stakeInput.value = String((Number(tokenFullBalance) * amount) / 100);
      selectedPool.stakeAmount = String(
        (Number(tokenFullBalance) * amount) / 100
      );
    }
  };

  const renderStakeModal = () => {
    if (!selectedPool) return null;

    const stakedPool = stakedPools.find(
      (pool) => pool.pool.id === selectedPool.id
    );
    const isAlreadyStaked = stakedPool && parseFloat(stakedPool.staked) > 0;

    return (
      <div className="modal" onClick={() => setSelectedPool(null)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-logo">
            <img src="logo.png" alt="Logo" />
            <h2>Stake for {selectedPool.label}</h2>
          </div>

          <div className="input-container">
            <input
              type="number"
              placeholder="Amount to stake"
              onChange={(e) => (selectedPool.stakeAmount = e.target.value)}
              className="input-field"
              id="stake-amount-input"
              disabled={!isNetworkCorrect}
            />
            <span className="input-currency">{TOKEN_TICKER}</span>
          </div>

          <div className="amount-section">
            <span
              className={`max-link ${
                selectedPercentage === 25 ? "selected" : ""
              }`}
              onClick={() => handleMaxClick(25)}
            >
              25%
            </span>
            <span
              className={`max-link ${
                selectedPercentage === 50 ? "selected" : ""
              }`}
              onClick={() => handleMaxClick(50)}
            >
              50%
            </span>
            <span
              className={`max-link ${
                selectedPercentage === 75 ? "selected" : ""
              }`}
              onClick={() => handleMaxClick(75)}
            >
              75%
            </span>
            <span
              className={`max-link ${
                selectedPercentage === 100 ? "selected" : ""
              }`}
              onClick={() => handleMaxClick(100)}
            >
              100%
            </span>
          </div>
          <div className="balance-info">
            Balance:{" "}
            {parseFloat(tokenFullBalance).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            })}{" "}
            {TOKEN_TICKER}{" "}
          </div>
          {isAlreadyStaked && (
            <p className="notice-message">
              You are already staking in this pool. <br />
              Staking more will relock and restart the lock period.
            </p>
          )}
          <div className="modal-buttons">
            <Button
              onClick={() =>
                handleStake(selectedPool.id, selectedPool.stakeAmount)
              }
              disabled={!isNetworkCorrect}
              className="modalStake"
            >
              Stake
            </Button>
            <Button
              onClick={() => setSelectedPool(null)}
              className="modalCancle"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`staking-pools ${actionInProgress ? "greyed-out" : ""}`}>
      <div className="header">
        <div className="header-logo">
          <img src="logo.png" alt="Logo" className="logo-image" />
          <h2>Cortensor Staking</h2>
        </div>
        <div className="wallet-info">
          <ConnectButton.Custom>
            {({
              account,
              chain,
              openAccountModal,
              openChainModal,
              openConnectModal,
              authenticationStatus,
              mounted,
            }) => {
              const ready = mounted && authenticationStatus !== "loading";
              const connected =
                ready &&
                account &&
                chain &&
                (!authenticationStatus ||
                  authenticationStatus === "authenticated");

              return (
                <div
                  {...(!ready && {
                    "aria-hidden": true,
                    style: {
                      opacity: 0,
                      pointerEvents: "none",
                      userSelect: "none",
                    },
                  })}
                >
                  {(() => {
                    if (!connected) {
                      return (
                        <button
                          onClick={openConnectModal}
                          type="button"
                          className="connectWalletButton"
                        >
                          Connect Wallet
                        </button>
                      );
                    }

                    if (chain.unsupported) {
                      return (
                        <button
                          onClick={openChainModal}
                          type="button"
                          className="wrongNetworkButton"
                        >
                          Wrong network
                        </button>
                      );
                    }

                    return (
                      <div className="walletConnectButton">
                        <div className="walletbalance">
                          <span>{account.displayBalance}</span>
                          <span>|</span>
                          <span>
                            {parseFloat(tokenFullBalance).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 4,
                              }
                            )}{" "}
                            {TOKEN_TICKER}
                          </span>
                        </div>
                        <button
                          onClick={openAccountModal}
                          className="openModalButton"
                          type="button"
                        >
                          {account.displayName}
                          {chain.hasIcon && (
                            <img
                              alt={chain.name ?? "Chain icon"}
                              src={chain.iconUrl}
                              style={{
                                width: 16,
                                height: 16,
                                marginLeft: 8,
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </button>
                      </div>
                    );
                  })()}
                </div>
              );
            }}
          </ConnectButton.Custom>
        </div>
      </div>
      <div className="staked-pools-section">
        <h2>Staking Pool</h2>
        <div className="pools-container">{renderPools()}</div>
      </div>
      {address && (
        <div className="staked-pools-section">
          {!isNetworkCorrect && (
            <p style={{ color: "red" }}>
              Please connect to the Arbitrum mainnet.
            </p>
          )}
          <h2>My Stakes & Rewards</h2>
          <div className="staked-pools-container">{renderStakedPools()}</div>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
      )}
      {renderStakeModal()}
      {confirmUnstake && (
        <div className="modal" onClick={() => setConfirmUnstake(false)}>
          <div
            className="modal-content same-width"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>
              <i
                className="fas fa-exclamation-triangle"
                style={{ marginRight: "8px", color: "black" }}
              ></i>
              Warning
            </h2>
            <p>
              You have remaining ETH rewards. Unstaking now will result in
              losing those ETH rewards.
              <br /> <b>Do you want to proceed?</b>
            </p>
            <div className="modal-buttons">
              <Button
                onClick={() => {
                  pendingAction();
                  setConfirmUnstake(false);
                }}
              >
                Withdraw Token
              </Button>
              <Button
                onClick={() => setConfirmUnstake(false)}
                className="modalCancle"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      {timelockWarning.show && (
        <div
          className="modal"
          onClick={() => setTimelockWarning({ show: false, unlockTime: null })}
        >
          <div
            className="modal-content modal-warning same-width"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>
              <i
                className="fas fa-exclamation-triangle"
                style={{ marginRight: "8px", color: "black" }}
              ></i>
              Warning
            </h2>
            <p>You cannot unstake until the timelock period has expired.</p>
            <span>
              <div className="timeSection">
                <div>Unlock At:</div>
                {timelockWarning.unlockTime.toLocaleString()}
              </div>
              <br />
              <div className="timeSection">
                <div>Unlock In:</div>
                {calculateTimeLeft(timelockWarning.unlockTime)} left
              </div>
            </span>
            <Button
              onClick={() =>
                setTimelockWarning({ show: false, unlockTime: null })
              }
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {confirmClaimRewards && (
        <div className="modal" onClick={() => setConfirmClaimRewards(false)}>
          <div
            className="modal-content same-width"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                style={{ marginRight: "8px" }}
              >
                <circle cx="8" cy="8" r="8" fill="black" />
                <path
                  d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm.1-4.995a.905.905 0 1 1 1.8 0l-.35 3.5a.55.55 0 0 1-1.1 0l-.35-3.5z"
                  fill="white"
                />
              </svg>
              Notice
            </h2>
            {compoundMessage ? (
              <p>
                Compounding ETH rewards will swap ETH for tokens and stake them,
                restarting the lock period.
                <br />
                <br /> <b>Do you want to proceed?</b>
              </p>
            ) : (
              <p>
                Claiming ETH rewards will lock your tokens again until the next
                unlock period.
                <br />
                <br /> <b>Do you want to proceed?</b>
              </p>
            )}
            <div className="modal-buttons">
              <Button
                onClick={() => {
                  pendingAction();
                  setConfirmClaimRewards(false);
                }}
              >
                {compoundMessage ? "Compound ETH" : "Claim ETH"}
              </Button>
              <Button
                onClick={() => setConfirmClaimRewards(false)}
                className="modalCancle"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      {noRewardsWarning.show && (
        <div
          className="modal"
          onClick={() => setNoRewardsWarning({ show: false, message: "" })}
        >
          <div
            className="modal-content same-width"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>
              <i
                className="fas fa-exclamation-triangle"
                style={{ marginRight: "8px", color: "black" }}
              ></i>
              No Rewards
            </h2>
            <p>{noRewardsWarning.message}</p>
          </div>
        </div>
      )}
      {actionInProgress && (
        <div className="overlay-modal">
          <div className="modal-content same-width">
            <h2>Action in Progress</h2>
            <div className="spinner"></div>
            <p>{statusMessage}</p>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="overlay-modal" onClick={() => setSuccessMessage("")}>
          <div
            className="modal-content same-width"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Success</h2>
            <p>{successMessage}</p>
            <Button
              onClick={() => {
                setSuccessMessage("");
                setSelectedPool(null);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {walletError && (
        <div className="overlay-modal" onClick={() => setWalletError("")}>
          <div
            className="modal-content same-width"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>
              {" "}
              <i
                className="fas fa-times-circle"
                style={{ marginRight: "8px", color: "black" }}
              ></i>
              Error
            </h2>
            <p>{walletError}</p>
            <Button onClick={() => setWalletError("")}>Close</Button>
          </div>
        </div>
      )}
      <footer className="footer">
        <div className="footer-menu-section">
          <div className="footer-logo-section">
            <div className="footer-img">
              <img src="logo.png" alt="Logo" className="stake-image" />
              <p>CORTENSOR</p>
            </div>
            <span>Unleashing the Future of AI Together.</span>
          </div>
          <div className="footer-nav-menu">
            <a href="https://www.cortensor.network" target="_blank">
              Home
            </a>
            <a href="https://docs.cortensor.network" target="_blank">
              Documentation
            </a>
            <a
              href="https://docs.cortensor.network/legal/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              href="https://docs.cortensor.network/legal/terms-of-use"
              target="_blank"
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div>
          <br />
        </div>
        <span>Cortensor © 2024. All Rights Reserved.</span>
      </footer>
    </div>
  );
};

export default StakingPools;
